import PropTypes from 'prop-types'
import Image from 'next/image';
import { Linker } from '@/components/content/linker'
import { config } from '@/lib/sanity.config'
import imageUrlBuilder from '@sanity/image-url'
const builder = imageUrlBuilder(config)


Instructor.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  image: PropTypes.object,
  professionalTitle: PropTypes.string,
  slug: PropTypes.string,
}

export function Instructor(props: { firstName: string; lastName: string; image: any; professionalTitle: string; slug: string; }) {

  const avatarSize = 60;
  const avatarHeight = 'h-' + avatarSize + 'px'
  const avatarWidth= 'h-' + avatarSize + 'px'

  const { firstName, lastName, image, professionalTitle, slug } = props;

  const bgImage = (val) => {
    return val ? `${builder.image(val).auto('format').width(avatarSize).height(avatarSize).url()}` : null
  }
  const bgImageBlur = (val) => {
    return val ? `${builder.image(val).auto('format').width(6).height(6).url()}` : null
  }

  return (
    <div className="flex items-center mt-2">
      {image ? <div className="flex-shrink-0">
        <span className={`flex-none`}>
        <Linker url={`/profile/${slug}`}><Image
          src={bgImage(image)}
          blurDataURL={bgImageBlur(image) || ''}
          placeholder={bgImageBlur(image) ? 'blur' : 'empty'}
          alt={`Avatar`}
          layout="intrinsic"
          width={avatarSize}
          height={avatarSize}
          className={`block rounded-full bg-slate-100`}
          /></Linker>
        </span>
      </div> : <span className={`flex-none`}></span>}

      <div className="ml-4">
        {(firstName || lastName) &&
          <h4 className='text-base font-semibold text-gray-700'><Linker url={`/our-instructors/${slug}`} classes="text-gray-700 hover:underline">{firstName} {lastName}</Linker></h4>
        }
        { professionalTitle && <div className="text-sm italic text-gray-500">{professionalTitle}</div>}
      </div>
    </div>
  )

}
