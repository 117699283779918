import PropTypes from 'prop-types'
import { format, parseISO } from 'date-fns'
import { PortableText } from '@/lib/sanity'
import serializers from '@/components/layout/content-components/serializers'
import { Instructor } from '@/components/content/instructor/teaser'

Review.propTypes = {
  date: PropTypes.any,
  instructors: PropTypes.array,
  review: PropTypes.array,
  title: PropTypes.string,
}

export function Review(props: { date: any; instructors: any; review: any; title: string; }) {

  const { date, instructors, review, title } = props;
  // console.log({instructors})

  return (
    <div className="relative mb-8">
      {review && <div className='relative z-10 px-2 pt-6 mb-4 text-xl italic font-normal text-gray-600 serif lg:px-16 lg:pt-10 lg:text-xl'>
        <PortableText blocks={review} serializers={serializers} />
      </div>}

      {title && <div className='px-2 lg:px-16'>
        <h3 className='text-xl italic font-semibold text-gray-600'>{title}</h3>
      </div>}

      {(date && date?.local) && <div className='px-2 lg:px-16'>
        <p className='text-sm italic text-gray-500'>{format(parseISO(date?.local), 'LLL, do. yyyy')}</p>
      </div>}

      {(instructors && instructors?.length > 0) &&
        <div className='px-2 mt-6 lg:px-16'>
          <h4 className='text-base font-semibold text-gray-500'>Reviewed Instructor(s)</h4>
          <div className='flex flex-wrap items-center mt-2'>
            {instructors.map(instructor =>
              <div className='flex items-center h-full mb-4 mr-6' key={instructor?._id}>
                <Instructor firstName={instructor?.firstName} lastName={instructor?.lastName} image={instructor?.image} professionalTitle={instructor?.professionalTitle} slug={instructor?.slug} />
              </div>
            )}
          </div>
        </div>
      }

      {/* Quote background */}
      <div className="absolute top-0 left-0 mt-3 ml-4 md:mt-5 md:ml-12 -z-0">
        <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-200 fill-current md:w-16 md:h-16" viewBox="0 0 24 24"><path d="M6.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L9.758 4.03c0 0-.218.052-.597.144C8.97 4.222 8.737 4.278 8.472 4.345c-.271.05-.56.187-.882.312C7.272 4.799 6.904 4.895 6.562 5.123c-.344.218-.741.4-1.091.692C5.132 6.116 4.723 6.377 4.421 6.76c-.33.358-.656.734-.909 1.162C3.219 8.33 3.02 8.778 2.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C2.535 17.474 4.338 19 6.5 19c2.485 0 4.5-2.015 4.5-4.5S8.985 10 6.5 10zM17.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L20.758 4.03c0 0-.218.052-.597.144-.191.048-.424.104-.689.171-.271.05-.56.187-.882.312-.317.143-.686.238-1.028.467-.344.218-.741.4-1.091.692-.339.301-.748.562-1.05.944-.33.358-.656.734-.909 1.162C14.219 8.33 14.02 8.778 13.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C13.535 17.474 15.338 19 17.5 19c2.485 0 4.5-2.015 4.5-4.5S19.985 10 17.5 10z" /></svg>
      </div>
    </div>
  )

}
