import PropTypes from 'prop-types'
import { capitalizeFirstLetter } from '@/utils/helpers'
import { FaTachometerAlt as MeterIcon } from 'react-icons/fa';
import { title } from 'process';

Level.propTypes = {
  title: PropTypes.string,
  value: PropTypes.array
}

export function Level(props: { title: any; value: any; }) {
  const { title, value } = props;

  return (
    <div className="">
      <dt className="text-sm font-bold tracking-normal text-gray-500 uppercase">{title}</dt>
      {/* <pre>{JSON.stringify(value, null, 2)}</pre> */}

      {value.map((name: any) => {
        switch (name) {
          case "reliability-engineer-introductory":
            return <dd className="flex items-center mt-1 text-base text-gray-700 align-baseline"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>Reliability Eng. - Introductory</dd>;
          case "reliability-engineer-intermediate":
            return <dd className="flex items-center mt-1 text-base text-gray-700 align-baseline"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>Reliability Eng. - Intermediate</dd>;
          case "rotating-equipment-engineer-introductory":
            return <dd className="flex items-center mt-1 text-base text-gray-700 align-baseline"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>Rotating Equipment Eng. - Introductory</dd>;
          case "rotating-equipment-engineer-intermediate":
            return <dd className="flex items-center mt-1 text-base text-gray-700 align-baseline"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>Rotating Equipment Eng. - Intermediate</dd>;
          case "rotating-equipment-engineer-advanced":
            return <dd className="flex items-center mt-1 text-base text-gray-700 align-baseline"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>Rotating Equipment Eng. - Advanced</dd>;
          case "rotating-equipment-engineer-expert":
            return <dd className="flex items-center mt-1 text-base text-gray-700 align-baseline"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>Rotating Equipment Eng. - Expert</dd>;
          case "condition-monitoring-specialist-introductory":
            return <dd className="flex items-center mt-1 text-base text-gray-700 align-baseline"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>C.M. Specialist - Introductory</dd>;
          case "condition-monitoring-specialist-intermediate":
            return <dd className="flex items-center mt-1 text-base text-gray-700 align-baseline"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>C.M. Specialist - Intermediate</dd>;
          case "condition-monitoring-specialist-advanced":
            return <dd className="flex items-center mt-1 text-base text-gray-700 align-baseline"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>C.M. Specialist - Advanced</dd>;
        }
      })}
    </div>
  )
}


// case "reliability-engineer-introductory":
//   return <dd className="flex items-center mt-1 text-sm text-gray-700"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>Reliability Eng. - Intro.</dd>;
// case "reliability-engineer-intermediate":
//   return <dd className="flex items-center mt-1 text-sm text-gray-700"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>Reliability Eng. - Interm.</dd>;
// case "rotating-equipment-engineer-introductory":
//   return <dd className="flex items-center mt-1 text-sm text-gray-700"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>Rotating Equip Eng - Intro.</dd>;
// case "rotating-equipment-engineer-intermediate":
//   return <dd className="flex items-center mt-1 text-sm text-gray-700"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>Rotating Equip. Eng. - Interm.</dd>;
// case "rotating-equipment-engineer-advanced":
//   return <dd className="flex items-center mt-1 text-sm text-gray-700"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>Rotating Equip. Eng. - Adv.</dd>;
// case "rotating-equipment-engineer-Expert":
//   return <dd className="flex items-center mt-1 text-sm text-gray-700"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>Rotating Equip. Eng. - Expert</dd>;
// case "condition-monitoring-specialist-introductory":
// return <dd className="flex items-center mt-1 text-sm text-gray-700"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>C.M. Specialist - Intro.</dd>;
// case "condition-monitoring-specialist-intermediate":
//   return <dd className="flex items-center mt-1 text-sm text-gray-700"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>C.M. Specialist - Interm.</dd>;
// case "condition-monitoring-specialist-advanced":
//   return <dd className="flex items-center mt-1 text-sm text-gray-700"><span className='mt-1 mb-auto mr-2'><MeterIcon /></span>C.M. Specialist - Adv.</dd>;