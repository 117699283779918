import PropTypes from 'prop-types'
import { Linker } from '@/components/content/linker'
import Icon from '@/components/shared/react-icons-wrapper'
import { transformToUpperCase } from '@/utils/helpers'

ButtonCTA.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string,
  type: PropTypes.string.isRequired,
  style: PropTypes.string,
  size: PropTypes.string,
  classes: PropTypes.string,
  theme: PropTypes.string,
}

export function ButtonCTA(props: { title: string; url: string; icon: string; type: string; style: string; size: string; theme: string; }) {

  const { title, url, icon, type, style, size, theme } = props

  function StyleClasses(style: string, theme: string) {
    if(style === 'primary'){
      return 'inline-flex items-center justify-center border border-transparent rounded-md shadow-sm bg-brand-1-400 text-white'
    } else if(style === 'secondary'){
      return 'inline-flex items-center justify-center text-gray-900 border border-transparent rounded-md shadow-sm bg-white hover:bg-gray-50 hover:border-gray-400'
    } else if(style === 'cta'){
      return 'inline-flex items-center justify-center border border-gray-200 rounded-md shadow-sm bg-white hover:bg-gray-50 hover:border-gray-400'
    } else {
      return null
    }
  }

  function SizeClasses(size: string, type: string) {
    if(size === 'lg' && type === 'button'){
      return 'font-medium text-base lg:text-lg px-4 py-3 '
    } if(size === 'sm' && type === 'button'){
      return 'font-medium text-sm lg:text-base px-2.5 py-1.5 '
    } else {
      return null
    }
  }

  return (
    <p>
      <Linker url={url} classes={`${SizeClasses(size, type)}
      ${StyleClasses(style, theme)}`}
      >{title}</Linker>
    </p>
  )
}
