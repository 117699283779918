import PropTypes from 'prop-types'
import { TimeConvert } from '@/utils/helpers'
import { HiClock as ClockIcon } from 'react-icons/hi';

Duration.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number
}

export function Duration(props: { title: any; value: number; }) {
  const { title, value } = props;
  return (
    <div className="">
      <dt className="text-sm font-bold tracking-normal text-gray-500 uppercase">{title}</dt>
      <dd className="flex items-center mt-1 text-base text-gray-700"><span className='mr-2'><ClockIcon /></span> {value > 60 ? TimeConvert(value) : value + ' min'}</dd>
    </div>
  )
}
