import PropTypes from 'prop-types'

FieldOfStudy.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string
}

export function FieldOfStudy(props: { title: any; value: number; }) {
  const { title, value } = props;
  return (
    <div className="">
      <dt className="text-sm font-bold tracking-normal text-gray-500 uppercase">{title}</dt>
      <dd className="flex items-center mt-1 text-base text-gray-700">{value}</dd>
    </div>
  )
}
