import { useState, useEffect, useCallback } from 'react'
import { myCourses } from '@/utils/supabase-client';
import toast from "@/components/toast";
import { updateUserMyCourses } from '@/utils/supabase-client';
import { HiStar, HiOutlineStar } from "react-icons/hi";
import { useUser } from 'utils/useUser';


export function MyCourseWidget({ courseID }) {
  const { isLoading, userDetails } = useUser();
  const [loading, setLoading] = useState(false);
  // console.log({courseID, courseURL, courseTitle})
  const [myCoursesList, setMyCoursesList] = useState([])

  const notify = useCallback((type, message) => {
    toast({ type, message });
  }, []);

  const myID = userDetails?.id;

  useEffect(() => {

    const getMyCourses = async () => {
      try {
        setLoading(true)
        const data = await myCourses(userDetails?.id)
        //notify("success", "Your message has been created.");
        setMyCoursesList(data[0]?.my_courses)
      } catch (error) {
        console.log({ error })
      } finally {
        setLoading(false)
      }
    }
    if(!isLoading && userDetails?.id){
      getMyCourses();
    }
  }, [isLoading, userDetails]);

  const handleUpdateMyCourses = async (val: boolean, courseID: string) => {
    let tempMyCourses: Array<string>;
    if (val === true) {
      tempMyCourses = [
        ...myCoursesList,
        courseID
      ]
      setMyCoursesList(myCoursesList => [...myCoursesList, courseID])
    } else {
      tempMyCourses = myCoursesList.filter(function (item) {
        return item !== courseID
      })
      setMyCoursesList(tempMyCourses)
    }
    try {
      setLoading(true)
      await updateUserMyCourses(myID, tempMyCourses)
      // notify("success", "The user role has now been updated.");
    } catch (error) {
      notify("error", error?.error_description || error?.message || 'There was an error saving the user account. Please try again.');
    } finally {
      setLoading(false)
    }
  }

  const handleTryAdd = () => {
    notify("warning", 'You must first create a Free Basic Membership account and be logged in to purchase the Full Membership.');
  }

  if (isLoading) {
    return <button className='px-3.5 py-2.5 text-lg text-indigo-100' disabled><HiOutlineStar /></button>
  }
  if (!userDetails) {
    return <button className='px-3.5 py-2.5 text-lg text-indigo-600' onClick={() => handleTryAdd()}><HiOutlineStar /></button>
  }

  return (
    <div>
      {myCoursesList.includes(courseID) ? (
        <button className='px-3.5 py-2.5 text-lg text-indigo-600' disabled={loading} onClick={() => handleUpdateMyCourses(false, courseID)}><HiStar /></button>
      ) : (
        <button className='px-3.5 py-2.5 text-lg text-indigo-600' disabled={loading} onClick={() => handleUpdateMyCourses(true, courseID)}><HiOutlineStar /></button>
      )}
    </div>
  )
}
