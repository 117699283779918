import PropTypes from 'prop-types'
import { TextCTA } from '@/components/content/call-to-action/text-cta'
import { TextIconCTA } from '@/components/content/call-to-action/text-icon-cta'
import { ButtonCTA } from '@/components/content/call-to-action/button-cta'
import { ButtonIconCTA } from '@/components/content/call-to-action/button-icon-cta'

CallToAction.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string,
  type: PropTypes.string.isRequired,
  style: PropTypes.string,
  size: PropTypes.string,
  classes: PropTypes.string,
  theme: PropTypes.string,
}

export function CallToAction(props: { title: string; url: string; icon: string; type: string; style: string; size: string; theme: string; }) {
  const { title, url, icon, type, style, size, theme } = props

  if(type === 'text'){
    return <TextCTA title={title} url={url} type={type} icon={icon} style={style} size={size} theme={theme} />
  } else if(type === 'textIcon'){
    return <TextIconCTA title={title} url={url} type={type} icon={icon} style={style} size={size} theme={theme} />
  } else if(type === 'button'){
    return <ButtonCTA title={title} url={url} type={type} icon={icon} style={style} size={size} theme={theme} />
  } else if(type === 'buttonIcon'){
    return <ButtonIconCTA title={title} url={url} type={type} icon={icon} style={style} size={size} theme={theme} />
  } else {
    return null
  }
}
