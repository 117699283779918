import PropTypes from 'prop-types'
import React from 'react'

AvailableFormats.propTypes = {
  title: PropTypes.string,
  formats: PropTypes.array
}

export function AvailableFormats(props: { title: any; formats: any; }) {

  const { title, formats } = props;

  const formatList = formats.map(format => format.title)
  // console.log(formatList.join(', '))
  return (
    // sm:col-span-2
    <div className="">
      <dt className="text-sm font-bold tracking-normal text-gray-500 uppercase">{title}</dt>
      <dd className="mt-1 text-sm text-gray-900">
        <span className="flex flex-wrap mt-2 space-y-3"><p className='text-base text-gray-700'>{formatList.join(', ')}</p></span>
      </dd>
  </div>
  )
}
